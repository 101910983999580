import React from "react";

const GetStarted = () => {
    return (
        <section className="s-pt-55 s-pb-0 s-pt-md-50 s-pt-lg-140 s-pb-lg-145 ls ms text-center steps-section" id="steps">
            <div className="container">
                <div className="col-12 text-center mb-55">
                    <h4>How To Get Started BMW</h4>
                    <p className="fs-20 color-dark">Get auto repairs in 3 easy steps</p>
                    <div className="divider-60 d-none d-xl-block"></div>
                </div>
                <div className="row c-mb-53 c-mb-lg-0">
                    <div className="col-12 col-lg-4 col-md-4">
                        <div className="steps">
                            <img src="images/step-1.jpg" alt=""/>
                            <div className="step-content">
                                <h6 className="fw-700">Upload your Photos</h6>
                                <p className="color-dark">Send us photos of your damaged bumper, frame or car scratches and dents.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4">
                        <div className="steps feature-step">
                            <img src="images/step-2.jpg" alt=""/>
                            <div className="step-content no-margin-bottom-h6">
                                <h6 className="fw-700">Receive a FREE</h6>
                                <h6 className="fw-700">SAME DAY</h6>
                                <h6 className="fw-700">Estimate</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4">
                        <div className="steps feature-step last-step">
                            <img src="images/step-3.jpg" alt=""/>
                            <div className="step-content">
                                <h6 className="fw-700">Schedule an Appointment </h6>
                                <p className="color-dark">Tell us a time and your location and we will come to you.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
}

export default GetStarted;